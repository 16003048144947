export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"64e2488e032e8d0018890bfa",
				"64e2488e032e8d0018890bfd",
				"64e24be1032e8d0018890c91",
				"64e24f2b032e8d0018890c9d",
				"64e24f3372a6d30018276813"
			]
		},
		"64e2488e032e8d0018890bfa": {
			"id": "64e2488e032e8d0018890bfa",
			"name": "404",
			"pageUrl": "404"
		},
		"64e2488e032e8d0018890bfd": {
			"id": "64e2488e032e8d0018890bfd",
			"name": "index",
			"pageUrl": "index"
		},
		"64e24be1032e8d0018890c91": {
			"id": "64e24be1032e8d0018890c91",
			"pageUrl": "services",
			"name": "Services"
		},
		"64e24f2b032e8d0018890c9d": {
			"id": "64e24f2b032e8d0018890c9d",
			"pageUrl": "about",
			"name": "About"
		},
		"64e24f3372a6d30018276813": {
			"id": "64e24f3372a6d30018276813",
			"pageUrl": "contact",
			"name": "Contact"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}