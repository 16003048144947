import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { useMediaQuery } from 'react-responsive';
import SolutionImages from "./home-page/solution-images/solution-images";
import HomeTrainings from "./home-page/home-trainings/home-trainings";
import HomeFeedbacks from "./home-page/home-feedbacks/home-feedbacks";
import Solutions from "./home-page/solutions/solutions";
import HomeAbout from "./home-page/about/home-about";
import Banner from './home-page/banner/banner'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';

export default (() => {
		const isMobile = useMediaQuery({ maxWidth: 600 });

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />

		<Banner/>
		<Solutions/>
		<SolutionImages/>
		<HomeAbout/>
		<HomeTrainings/>
		<HomeFeedbacks/>

		{/* <RawHtml>
			<style place={"endOfHead"} rawKey={"64e2488e032e8d0018890bf3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml> */}
	</Theme>;
});