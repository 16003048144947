import React, { useEffect, useRef } from 'react';
import GsapScrollImage from '../animations/gsap'; // Adjust the path as necessary
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function About() {

	// let t1 = gsap.timeline({
	// 	scrollTrigger: {
	// 		trigger: '.myelem',
	// 		start: 'top center',
	// 		end: 'bottom center',
	// 		scrub: true,
	// 		markers: true
	// 	}
	// })

	// t1.to('.myelem', {
	// 	x: 200
	// })

    return (
		<div style={{height: "200vh"}}>
			{/* <div style={{ position: 'relative', height: '200vh', top: 200, left: 20 }} >
				<img className="myelem" src={'/images/background/cube3.png'} alt="Animated" style={{ height: 200, width: 200}} />
			</div> */}
			<GsapScrollImage
				imageSrc="/images/background/cube3.png"
				x={50}  // Horizontal movement
				y={100} // Vertical movement
				start="top center"
				end="bottom top"
				top={200} // CSS top value
				left={30} // CSS left value
			/>
		</div>
    );
};

export default About