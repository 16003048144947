import React from 'react';

import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Strong, Hr, Icon } from "@quarkly/widgets";
import { Override} from "@quarkly/components";
import Slider from "react-slick";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { FaBeer } from 'react-icons/fa';
import Feedback from './feedback';

import './home-feedbacks.css'

function HomeFeedbacks() {

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    //   centerMode: true,
    //   centerPadding: "5px",
      dots: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 768, // At screen widths less than 768px
          settings: {
            slidesToShow: 1, // Show only 1 slide for smaller screens
            slidesToScroll: 1,
            arrows: false,

          },
        },
        {
          breakpoint: 1024, // At screen widths less than 1024px but greater than 768px
          settings: {
            slidesToShow: 2, // Optional: Show 2 slides for medium screens (like tablets)
            slidesToScroll: 1,
          },
        },
      ],
      nextArrow: <SlArrowRight style={{ color: 'blue', fontSize: '24px' }}/>,
      prevArrow: <SlArrowLeft style={{ color: 'blue', fontSize: '24px' }}/>,
    };

    return (
      <Section
        text-align="center"
        padding="80px 0"
        sm-padding="40px 0"
        font="16px "
      >
        <div className=".icon-arrow-left" />
        <Text
          as="h1"
          font="normal 900 48px/1.2 --fontFamily-googleMulish"
          md-font="--headline2"
          margin="20px 0 0 0"
          color="--darkL1"
        >
          Our Clients About Us
          <br />
        </Text>
        <Text
          as="p"
          font="normal 400 20px/1.5 --fontFamily-sans"
          margin="20px 0 0 0"
          color="--darkL2"
        >
          If you're interested, we'd love to hear your feedback. Feel free to
          visit our <Link href="#">contact page</Link> and send us an email with
          your thoughts. We're all ears and excited to hear what you have to
          say!
        </Text>
        <Slider {...settings}>
          <Feedback
            name="Daniel"
            feedback="French training was superb! Enjoyed interactive lessons & cultural insights. Feeling more confident expressing myself. Merci beaucoup!"
            date="October 21, 2022"
          />
          <Feedback
            name="Maria"
            feedback="Impressed with German training! Structured modules, interactive practices, supportive instructors. Feeling equipped to converse confidently. Vielen Dank!"
            date="May 01, 2023"
          />
          <Feedback
            name="Cosmin"
            feedback="German training exceeded expectations! Engaging exercises, patient instructors, diverse resources. Boosted my language skills immensely."
            date="June  13, 2023"
          />
          <Feedback
            name="Cosmin"
            feedback="German training exceeded expectations! Engaging exercises, patient instructors, diverse resources. Boosted my language skills immensely."
            date="June  13, 2023"
          />
        </Slider>
      </Section>
    );
}

export default HomeFeedbacks