import React from 'react';

import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Strong, Hr, Icon } from "@quarkly/widgets";
import { Override} from "@quarkly/components";

import './home-trainings.css'

function HomeTrainingSingle({
        trainingName,
        description,
        image,
        height='500px',
        link
    }) {

    return (
        // <LinkBox
		// 		display="flex"
		// 		margin="40px 0px 84px 0px"
		// 		md-flex-direction="column"
		// 		md-align-items="center"
		// 		md-justify-content="center"
		// 		flex-direction="row"
		// 		align-items="flex-start"
		// 		flex-wrap="wrap"
		// 		lg-margin="0px 0px 16px 0px"
		// 	>
		// 		<Box
		// 			width="60%"
		// 			align-items="flex-start"
		// 			display="flex"
		// 			justify-content="flex-start"
		// 			margin="0px 0px 0px 0px"
		// 			md-width="100%"
		// 			lg-width="100%"
		// 			lg-padding="0px 0px 0px 0px"
		// 			background="rgba(0, 0, 0, 0) url() 0% 0% /auto repeat scroll padding-box"
		// 		>
		// 			<Image
		// 				// src="/images/background/ger2.png"
		// 				src={image}
		// 				border-radius="0px"
		// 				max-width="100%"
		// 				width="100%"
		// 				object-fit="fill"
		// 				lg-max-height="392px"
		// 				sm-max-height="213px"
		// 				justify-content="flex-start"
		// 				background="rgba(0, 0, 0, 0) url()"
		// 				margin="0px 30px 0px 0px"
		// 				sm-margin="0px 0px 0px 0px"
		// 				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
		// 			/>
		// 		</Box>
		// 		<Box
		// 			width="40%"
		// 			padding="100px 0px 0px 16px"
		// 			display="flex"
		// 			align-items="flex-start"
		// 			justify-content="flex-start"
		// 			md-width="100%"
		// 			sm-align-items="flex-start"
		// 			sm-flex-direction="column"
		// 			sm-justify-content="flex-start"
		// 			flex-direction="column"
		// 			lg-width="100%"
		// 			lg-flex-direction="row"
		// 			lg-justify-content="space-between"
		// 			lg-padding="16px 12px 16px 12px"
		// 			sm-padding="16px 12px 16px 0px"
		// 		>
		// 			<Box
		// 				padding="0px 50px 0px 0px"
		// 				lg-width="70%"
		// 				sm-width="100%"
		// 				lg-padding="0px 0px 0px 0px"
		// 				color="#333333"
		// 			>
		// 				<Text margin="0px 0px 8px 0px" font="normal bold 30px/34px --fontFamily-googleNotoSerifGeorgian" color="#333333">
		// 					{trainingName}
		// 				</Text>
		// 				<Text
		// 					margin="0px 0px 0px 0px"
		// 					font="normal 300 18px/31px &quot;Mulish&quot;, Sans-serif"
		// 					color="#777777"
		// 					letter-spacing="0.3px"
		// 					width="80%"
		// 					sm-width="100%"
		// 				>
		// 					{/* Incredible design for gardens or even parks is not a new word to our team. We are ready for large scales and areas. Find out about our projects. */}
        //                     {description}
		// 					<br />
		// 				</Text>
		// 				<Box
		// 					min-width="100px"
		// 					display="flex"
		// 					max-height="fit-content"
		// 					padding="10px 0px 0px 0px"
		// 					height="fit-content"
		// 				>
		// 					<Text
		// 						margin="0px 0px 0px 0px"
		// 						max-height="max-content"
		// 						display="inline-flex"
		// 						padding="0px 20px 0px 0px"
		// 						width="fit-content"
		// 						height="fit-content"
		// 						color="#333333"
		// 						font="bold 18px/40px &quot;Mulish&quot;, Sans-serif"
		// 					>
		// 						From
		// 						<br />
		// 						To
		// 					</Text>
		// 					<Text
		// 						margin="0px 0px 0px 0px"
		// 						max-height="max-content"
		// 						display="inline-flex"
		// 						padding="0px 0px 0px 10px"
		// 						height="fit-content"
		// 						color="#00996d"
		// 						font="bold 18px/40px &quot;Mulish&quot;, Sans-serif"
		// 					>
		// 						A1.1
		// 						<br />
		// 						B2.2
		// 					</Text>
		// 				</Box>
		// 			</Box>
		// 		</Box>
		// 	</LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
          sm-align-items="flex-start"
        >
          <Box
            style={{
              position: "relative", // Make the Box a relative container
              padding: "0px 0px 0px 0px",
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              margin: "0px 30px 0px 0px",
              width: "100%", // Adjusted for responsiveness
              flexDirection: "row",
              // Add other responsive styles as needed
            }}
          >
            <Image
              src={image}
              style={{
                borderRadius: "0px",
                maxWidth: "400px",
                maxHeight: "500px",
                height: height,
                //   width: '100%', // Make the image take the full width of the container
                // Add other responsive styles as needed
              }}
            />
            <span
              className="course-name-image"
              style={{
                position: "absolute", // Position the text absolutely
                bottom: "20px", // Adjust the position as needed
                left: "0px", // Adjust the position as needed
                fontWeight: "bold",
                fontSize: "72px",
                lineHeight: "48px",
                fontFamily: '"Noto Serif Georgian", serif',
                color: "rgb(67, 99, 114)",
                zIndex: 2,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                textShadow: "0 0 8px #fff",
                borderRadius: 20,
              }}
            >
              {/* <div class="vertical-text">
		<span>E</span>
		<span>N</span>
		<span>G</span>
		<span>L</span>
		<span>I</span>
		<span>S</span>
		<span>H</span>
	</div> */}
              {trainingName}
            </span>
          </Box>
          <Box
            width="40%"
            padding="100px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
            sm-padding="16px 12px 16px 0px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
              color="#333333"
            >
              {/* <Text margin="0px 0px 8px 0px" font="normal bold 30px/34px --fontFamily-googleNotoSerifGeorgian" color="#333333">
							English
						</Text> */}
              <Text
                margin="0px 0px 0px 0px"
                font='normal 300 18px/31px "Mulish", Sans-serif'
                color="#777777"
                letter-spacing="0.3px"
                width="80%"
                sm-width="100%"
              >
                {description}
              </Text>
              <Box
                min-width="100px"
                display="flex"
                max-height="fit-content"
                padding="10px 0px 0px 0px"
                height="fit-content"
              >
                {/* <Text
                  margin="0px 0px 0px 0px"
                  max-height="max-content"
                  display="inline-flex"
                  padding="0px 20px 0px 0px"
                  width="fit-content"
                  height="fit-content"
                  color="#333333"
                  font='bold 18px/40px "Mulish", Sans-serif'
                >
                  From
                  <br />
                  To
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  max-height="max-content"
                  display="inline-flex"
                  padding="0px 0px 0px 10px"
                  height="fit-content"
                  color="#00996d"
                  font='bold 18px/40px "Mulish", Sans-serif'
                >
                  A1.1
                  <br />
                  B2.2
                </Text> */}
              </Box>
            </Box>
          </Box>
        </LinkBox>
    )
}

export default HomeTrainingSingle