import React from "react";
import Index from "pages/index";
import Services from "pages/services";
// import About from "pages/about";
import Contact from "pages/contact";
import Page404 from "pages/page404";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import Header from "components/header/Header";
import Footer from "components/footer/footer";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/about";

import ScrolltoTop2 from './ScrollToTop'

import './App.css'

const GlobalStyles = createGlobalStyle`
    body, html {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;

export default () => (
    <Router>
        <GlobalStyles />
        <ScrollToTop smooth color="#ad886e" />

        <Helmet>
			<title>
				Lingua Wings
			</title>
			<meta name={"description"} content={""} />
			<link rel={"shortcut icon"} href={"/images/icons/icon1.png"} type={"image/x-icon"} />
		</Helmet>
        <Header/>
        <ScrolltoTop2>
            <Switch>
                <Route exact path='/' component={Index}/>
                <Route exact path='/index' component={Index}/>
                <Route exact path='/services' component={Services}/>
                <Route exact path='/about' component={About}/>
                <Route exact path='/contact' component={Contact}/>
                <Route component={Page404}/>
            </Switch>
        </ScrolltoTop2>
        <Footer/>
    </Router>
);
