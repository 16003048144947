import React from 'react';
import './DotLink.css';

function DotLink({ href, children, isActive, onClick }) {
    return (
        <a 
            href={href} 
            className={`nav-link ${isActive ? 'active-link' : ''}`}
            onClick={onClick}
        >
            {children}
            <span className="circle"></span>
        </a>
    );
}

export default DotLink;
