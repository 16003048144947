import React from 'react';

import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Strong, Hr, Icon } from "@quarkly/widgets";
import Arrow from '@elsdoerfer/react-arrow';
// import { Override} from "@quarkly/components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './home-about.css'
import PanelHeader from '../../../components/panel-header/PanelHeader';

function HomeAbout() {
    return (
        <Section className="section-home-about" padding="180px 0 120px 0" sm-padding="180px 0 60px 0" margin="-180px 0 0 0">
			{/* <Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" /> */}
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				md-width="100%"
				padding="0px 100px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				{/* <Text margin="0px 0px 0px 0px" color="--lightD2" font="normal bold 14px/24px --fontFamily-googleMulish" letter-spacing="2.8px">
					ABOUT
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="normal bold 48px/55px --fontFamily-googleNotoSerifGeorgian"
					md-margin="0px 0px 30px 0px"
					text-align="left"
				>
					Starting your journey{" "}
					<br />
					with the best trainers
				</Text> */}

				<PanelHeader smallText={"About"} bigText={"Starting your journey with the best trainers"} blueBackground={true} whiteColor={true}/>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				{/* <Text margin="0px 0px 0px 0px" color="--lightD2" font="normal bold 22px/37px 'Georgia', Sans-serif" letter-spacing={0}>
					<br />
					Dreaming about an individual landscaping greenery? Take the first step towards your main needs and desires.
					<br />
					<br />
				</Text> */}
				<ul>
					<li className="custom-icon-li">
						<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="normal 3 18px/31px &quot;Muli&quot;, Sans-serif">
							Beautiful gardening is not so hard as it seems. The best way to start with it is to create a good plan with detailed steps. It should include information about the area, soil analysis and leading plants in general design.
						</Text>
					</li>
					<li className="custom-icon-li">
						<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="normal 3 18px/31px &quot;Muli&quot;, Sans-serif">
							We also advise not to miss the planning for underground irrigation systems. In this section, you will find all the necessary recommendations for it.
						</Text>
					</li>
					{/* ... other items */}
				</ul>
                <button className="home-about-main-button">
                    Discover more
                    <Arrow
                        angle={90}
                        length={50}
                        color={'#ad886e'}
                        style={{
                            width: '80px'
                        }}
                    />
				</button>
			</Box>
		</Section>
    )
}

export default HomeAbout