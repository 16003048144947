import React , {useEffect} from 'react';

import { Text, Box, Section, Span, Button } from "@quarkly/widgets";
import { Override} from "@quarkly/components";
import ImageScrollAnimation from '../../../animations/image.scroll.animation'
import Arrow from '@elsdoerfer/react-arrow';
import { useMediaQuery } from 'react-responsive';

import './banner.css'
import GsapScrollImage from 'animations/gsap';

// function adjustBannerHeight() {
//     var banner = document.querySelector('.banner-section');
//     banner.style.height = window.innerHeight + 'px';
// }

// function Banner() {

// 	const isMobile = useMediaQuery({ maxWidth: 600 });

// 	// adjustBannerHeight();
// 	// const animatedElement = useRef(null);

// 	// useEffect(() => {
// 	// 	// GSAP animation
// 	// 	gsap.to(animatedElement.current, {
// 	// 	x: 100, // Move 100px to the right
// 	// 	duration: 2, // Duration of 2 seconds
// 	// 	ease: "power3.out" // An easing function
// 	// 	});
// 	// }, []);

// 	useEffect(() => {
//         if (isMobile) {
//             // Adjust the banner height when the component mounts
//             adjustBannerHeight();

//             // Add a resize event listener to adjust the height when the window is resized
//             window.addEventListener('resize', adjustBannerHeight);

//             // Cleanup function to remove the event listener
//             return () => {
//                 window.removeEventListener('resize', adjustBannerHeight);
//             };
//         }
//     }, [isMobile]); // Dependency array now includes isMobile


function adjustBannerSize() {
    var banner = document.querySelector('.banner-section');
    if (banner) {
        // Adjust height
        banner.style.height = window.innerHeight + 'px';

        // Adjust width
        banner.style.width = document.documentElement.clientWidth + 'px';
    }
}

function Banner() {
    const isMobile = useMediaQuery({ maxWidth: 600 });

    useEffect(() => {
        if (isMobile) {
            // Adjust the banner size when the component mounts
            adjustBannerSize();

            // Add a resize event listener to adjust the size when the window is resized
            window.addEventListener('resize', adjustBannerSize);

            // Cleanup function to remove the event listener
            return () => {
                window.removeEventListener('resize', adjustBannerSize);
            };
        }
    }, [isMobile]); // Dependency array now includes isMobile


	return (
        <Section
			className="banner-section"
			padding="200px 0 240px 0"
			md-padding="120px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
			background="rgba(0, 0, 0, 0) url('/images/background/panel.png') top  right /cover repeat scroll border-box"
			// sm-background="rgba(0, 0, 0, 0) url('/images/background/done2.png') 0% 20% /cover repeat padding-box"
			sm-background="rgba(0, 0, 0, 0) url('/images/background/panel3.png') 0% 20% /cover repeat padding-box"

			md-background="rgba(0, 0, 0, 0) url('/images/background/panel.png') top 0px /cover repeat scroll border-box"
			sm-padding="100px 0 50px 0"
			sm-height="-webkit-fill-available;"
            sm-position="relative"
			position="relative"
		>
			{isMobile ?
				<>
					{/* <ImageScrollAnimation imageSrc={"/images/background/cube3.png"} altText="Descriptive Alt Text for the Image"
						className="resized-image"
						width="200px" 
						height="200px"
						x={20}
						y={160}
						top={220}
						left={150}
					/>

					<ImageScrollAnimation imageSrc={"/images/background/cube8.png"} altText="Descriptive Alt Text for the Image"
						className="resized-image"
						width="300px" 
						height="300px"
						x={380}
						y={-690}
						top={120}
						left={50}
					/> */}

					<GsapScrollImage
						imageSrc="/images/background/cube3.png"
						x={25}  // Horizontal movement
						y={160} // Vertical movement
						start="top center"
						end="bottom top"
						top={80} // CSS top value
						left={0} // CSS left value
						height={200}
						width={200}
					/>

					<GsapScrollImage
						imageSrc="/images/background/cube8.png"
						x={60}  // Horizontal movement
						y={-40} // Vertical movement
						start="100% 30%"
						end="bottom top"
						top={-40} // CSS top value
						right={-40}
						height={300}
						width={300}
					/>
				</>
			:
			<>
				{/* <ImageScrollAnimation imageSrc={"/images/background/cube3.png"} altText="Descriptive Alt Text for the Image"
					className="resized-image"
					width="200px" 
					height="200px"
					x={20}
					y={-0}
					top={20}
					left={150}
				/> */}
			</>
			}

            {/* <ImageScrollAnimation imageSrc={"/images/background/cube11.png"} altText="Descriptive Alt Text for the Image"
                className="resized-image"
                width="100px" 
                height="100px"
                x={980}
                y={-690}
            /> */}

			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				className="banner-section-box"
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				width="60%"
				justify-content="center"
				lg-padding="50px 80px 50px 0px"
				md-width="100%"
				// sm-position="relative"
				sm-z-index="2"
				margin="0px 0px 0px 5%"
				lg-margin="0px 0px 0px 60px"
				padding="0px 5% 0px 5%"
				sm-padding="0px 25px 35px 25px"
				sm-display="flex"
				sm-min-width="300px"
				sm-margin="0px 0px 0px 0px"
                sm-position="absolute"
                sm-bottom="0px"
				position="absolute"
                // bottom="100px"
			>
				<Text
                    className="banner-main-text"
					color="#f0e6e6"
					display="block"
					sm-display="block"
					sm-align-self="auto"
					sm-order="0"
					width="65%"
					sm-width="80%"
				>
					{/* Start your trainings now at Lingua Wings */}
                    Start your language journey now with  {" "}
                    <span className='banner-main-sitename'>LinguaWings</span>
				</Text>
				<Text className="banner-second-text">
                    Embark on your linguistic journey with personalized courses, fostering fluency, cultural insights, enjoyable learning experiences and seamless global communication.
				</Text>
				<button className="banner-main-button">
                    Discover more
                    <Arrow
                        angle={90}
                        length={50}
                        color={'#ad886e'}
                        style={{
                            width: '80px'
                        }}
                    />
				</button>
			</Box>
		</Section>
    );
}

export default Banner;