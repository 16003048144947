import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Twirl as Hamburger } from "hamburger-react";
import CurvyLink from "../curvyLinks/CurvyLink";

import "./Header.css";
import DotLink from "components/dotLink/DotLink";

const Header = () => {
  const [isSticky, setSticky] = useState(false);
  const [isNavActive, setNavActive] = useState(false);
  const [menuState, setMenuState] = useState('closed');  // 'closed', 'opened', or 'closing'


  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
  }, []);

  // const handleMenuClick = () => {
  //   setNavActive(!isNavActive);

  //   if (!isNavActive) {
  //     // When opening the menu
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     // When closing the menu
  //     document.body.style.overflow = "";
  //   }
  // };

  const handleMenuClick = () => {
    if (menuState === 'closed' || menuState === 'closing') {
      setMenuState('opened');
      document.body.style.overflow = "hidden";
    } else {
      setMenuState('closing');
      setTimeout(() => setMenuState('closed'), 600);
      document.body.style.overflow = "";
    }
  };

  const history = useHistory();
  const navigateToHome = () => {
    history.push("/");
  };

  const location = useLocation();  // Get the current path
    const [activeLink, setActiveLink] = useState(location.pathname);  // Initialize with current path

    useEffect(() => {
      setActiveLink(location.pathname);  // Update active link whenever path changes
    }, [location.pathname]);

  return (
    <header className={isSticky ? "sticky" : ""}>
      <nav>
        {/* <a href="#home" id="logo">Study Smart</a> */}
        <img
          className="logo"
          src={"/images/icons/icon4.png"}
          alt="LinguaWings"
          onClick={navigateToHome}
        />
        <div className="fas dn">
          <Hamburger
            rounded
            size={20}
            color="#00996d"
            toggled={menuState === "opened"}
            toggle={handleMenuClick}
            // onToggle={handleMenuClick}
          />
        </div>
        <ul id="nav-bar" className={menuState}>
          <li>
            <DotLink href="/trainings" isActive={activeLink === "/trainings"} onClick={() => setActiveLink("/trainings")}>
              Trainings
            </DotLink>
          </li>
          <li>
            <DotLink href="/about" isActive={activeLink === "/about"} onClick={() => setActiveLink("/about")}>
              About
            </DotLink>
          </li>
          <li>
            <DotLink href="/contact" isActive={activeLink === "/contact"} onClick={() => setActiveLink("/contact")}>
              Contact
            </DotLink>
          </li>
          {/* <li>
            <DotLink href="trainings">Trainings</DotLink>
          </li>
          <li>
            <CurvyLink href="about">About</CurvyLink>
          </li>
          <li>
            <CurvyLink href="contact">Contact</CurvyLink>
          </li> */}
          <li>
            <button
              onClick="location.href='tel:+1234567890'"
              className="call-button"
            >
              Call Us
            </button>

            {/* <a href="#download-app">Call</a> */}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
