import React from 'react';

import { FaFacebook, FaInstagram, FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
import { Link, Image, LinkBox, Box, Icon } from "@quarkly/widgets";
import { BsDot } from "react-icons/bs";
import './Footer.css'

function Footer() {
    return (
        <div style={{marginTop: 50, marginBottom: 25}}>
			<LinkBox margin="0px 0px 10px 0px" href="#">
				<Image src="/images/icons/icon4.png" alt="logo" display="block" width="160px" margin="0px 0px 0px 0px" />
			</LinkBox>
			<Box
				display="flex"
				md-justify-content="center"
				md-align-items="center"
				justify-content="center"
				align-items="center"
				margin="0px 0px 50px 0px"
				flex-wrap="wrap"
				sm-margin="0px 0px 36px 0px"
				
			>
				<Link
					color="--darkL2"
					padding="6px 12px 6px 12px"
					margin="0px 6px 0px 0px"
					md-margin="0px 6px 0px 6px"
					href="/"
					font="normal 500 16px/1.5 --fontFamily-sans"
					text-decoration-line="initial"
					border-color="--color-darkL2"
					letter-spacing="1px"
					hover-color="--primary"
				>
					HOME
				</Link>
				<Icon category="bs" icon={BsDot} size="24px" color="#b2b8be" />
                <Link
					padding="6px 12px 6px 12px"
					margin="0px 6px 0px 6px"
					href="/trainings"
					font="normal 500 16px/1.5 --fontFamily-sans"
					text-decoration-line="initial"
					color="--darkL2"
					border-color="--color-darkL2"
					letter-spacing="1px"
					hover-color="--primary"
				>
					TRAININS
				</Link>
				<Icon category="bs" icon={BsDot} size="24px" color="#b2b8be" />
				<Link
					padding="6px 12px 6px 12px"
					margin="0px 6px 0px 6px"
					href="/about"
					font="normal 500 16px/1.5 --fontFamily-sans"
					text-decoration-line="initial"
					color="--darkL2"
					border-color="--color-darkL2"
					letter-spacing="1px"
					hover-color="--primary"
				>
					ABOUT
				</Link>
				{/* <Icon category="bs" icon={BsDot} size="24px" color="#b2b8be" /> */}
				
				<Link
					padding="6px 12px 6px 12px"
					margin="0px 6px 0px 6px"
					href="contact"
					font="normal 500 16px/1.5 --fontFamily-sans"
					text-decoration-line="initial"
					color="--darkL2"
					border-color="--color-darkL2"
					letter-spacing="1px"
					hover-color="--primary"
				>
					CONTACT
				</Link>
				{/* <Icon category="bs" icon={BsDot} size="24px" color="#b2b8be" />
				<Link
					padding="6px 12px 6px 12px"
					margin="0px 6px 0px 6px"
					href="#"
					font="normal 500 16px/1.5 --fontFamily-sans"
					text-decoration-line="initial"
					color="--darkL2"
					border-color="--color-darkL2"
					letter-spacing="1px"
					hover-color="--primary"
				>
					CONTACTS
				</Link> */}
			</Box>
			<Box
				display="flex"
				grid-template-columns="repeat(5, 1fr)"
				grid-gap="16px 24px"
				align-self="center"
				margin="0 0px 0 0px"
				justify-content="center"
			>
				<LinkBox href="/">
					<Icon
						category="fa"
						icon={FaFacebook}
						size="24px"
						color="#5a5d64"
						hover-color="--dark"
						transition="background-color 1s ease 0s"
					/>
				</LinkBox>
				<LinkBox href="/">
					<Icon
						category="fa"
						icon={FaInstagram}
						size="24px"
						color="#5a5d64"
						hover-color="--dark"
						transition="background-color 1s ease 0s"
					/>
				</LinkBox>
				<LinkBox href="/">
					<Icon
						category="fa"
						icon={FaTwitterSquare}
						size="24px"
						color="#5a5d64"
						hover-color="--dark"
						transition="background-color 1s ease 0s"
					/>
				</LinkBox>
				<LinkBox href="/">
					<Icon
						category="fa"
						icon={FaGithub}
						size="24px"
						color="#5a5d64"
						hover-color="--dark"
						transition="background-color 1s ease 0s"
					/>
				</LinkBox>
				<LinkBox href="/">
					<Icon
						category="fa"
						icon={FaYoutube}
						size="24px"
						color="#5a5d64"
						hover-color="--dark"
						transition="background-color 1s ease 0s"
					/>
				</LinkBox>
			</Box>
		</div>
    )
}

export default Footer