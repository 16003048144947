import React from "react";

import {
  Theme,
  Link,
  Image,
  Text,
  LinkBox,
  Box,
  Section,
  Span,
  Button,
  Strong,
  Hr,
  Icon,
} from "@quarkly/widgets";
import { Override } from "@quarkly/components";
import Slider from "react-slick";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { FaBeer } from "react-icons/fa";

import "./home-feedbacks.css";

function Feedback({ name, image, feedback, date }) {
  return (
    <Box
      min-width="100px"
      padding="40px 0px 40px 0px"
      width="30%"
      sm-width="100%"
      display="flex"
      align-items="center"
      justify-content="flex-start"
      flex-direction="column"
      border-radius="25px"
      background="#DFEAE2"
      height="460px"
      margin="0px 0px 20px 0px"
      className="slider-item"
    >
      <Image
        display="block"
        width="100px"
        height="100px"
        border-radius="50%"
        object-fir="cover"
        background="rgba(0, 0, 0, 0) url('/images/background/ger2.png') 0% 0% /auto repeat scroll padding-box"
        border-width="8px"
        border-style="solid"
        border-color="#fdcfaa"
      />
      <Text
        margin="30px 0px 0px 0px"
        font="600 24px/1.4285 --fontFamily-googleRubik"
        letter-spacing="0px"
        color="#535353"
      >
        {name}
      </Text>
      <Hr
        margin="20px 0px 0px 0px"
        width="20%"
        color="#d53100"
        height="2px"
        background="#ff6b40"
      />
      <Text
        margin="20px 0px 0px 0px"
        padding="0px 20px 0px 20px"
        font="400 17px/1.5 --fontFamily-googlePoppins"
        color="--darkL2"
      >
        {feedback}
      </Text>
      <Text
        margin="30px 0px 0px 0px"
        color="#ff5b2e"
        font="600 13px/1.4285 --fontFamily-googlePoppins"
      >
        {date}
      </Text>
    </Box>
  );
}

export default Feedback;
