import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const GsapScrollImage = ({ 
    imageSrc, 
    x, 
    y, 
    start, 
    end, 
    top, 
    left,
    right,
    height,
    width
}) => {
    const imageRef = useRef(null);

    useEffect(() => {
        gsap.to(imageRef.current, {
            scrollTrigger: {
                trigger: imageRef.current,
                start: start,
                end: end,
                scrub: true,
                // markers: true
            },
            x: x,
            y: y
        });
    }, [start, end, x, y]); // Dependency array ensures the effect runs again if these props change

    return (
        <div ref={imageRef} style={{ position: 'absolute', top: top, left: left, right: right }}>
            <img className="myelem" src={imageSrc} alt="Animated" style={{ height: height, width: width }} />
        </div>
    );
};

export default GsapScrollImage;



// import React, { useEffect, useRef } from 'react';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

// gsap.registerPlugin(ScrollTrigger);

// const GsapScrollImage = () => {
//     const imageRef = useRef(null);

//     useEffect(() => {
//         gsap.to(imageRef.current, {
//             scrollTrigger: {
//                 trigger: imageRef.current,
//                 start: "top 30%",
//                 end: "bottom buttom",
//                 scrub: true,
//                 markers: true

//             },
//             x: 20, // Horizontal movement, adjust as needed
//             y: 480, // Vertical movement, adjust as needed
//         });
//     }, []);

//     return (
//         <div  ref={imageRef} style={{ position: 'absolute', top: 400, left: 10 }}>
//             <img className="myelem" ref={imageRef} src={'/images/background/cube3.png'} alt="Animated" style={{ height: 200, width: 200}} />
//         </div>
//     );
// };

// export default GsapScrollImage;
