import React from 'react';

import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Strong, Hr, Icon } from "@quarkly/widgets";
import { Override} from "@quarkly/components";

import './solution-images.css'

function SolutionImages() {
    return (
        <Section padding="0px 0 80px 0" border-radius=" 0 0 0 0">
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				flex-direction="column"
				margin="0px 0px 32px 0px"
				width="100%"
			/>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="1fr"
				width="100%"
			>
				<Box position="relative" display="flex" flex-direction="column">
					<Box padding="0px 8px 0px 8px" margin="0px 0px 16px 0px">
						<Box min-width="100px" min-height="8px" padding="6px 0px 6px 0px" display="flex">
							<Text className="text-numbers" margin="0px 0px 0px 0px" letter-spacing="-14px" font="140px/50px  'Georgia', Sans-serif">
								01
							</Text>
							<Text color="#333333" font="normal bold 22px/37px  'Georgia', Sans-serif" letter-spacing={0} transform="translate3d(-64px, 24px, 0px)">
								<Strong>
									Authentic Learning Materials
								</Strong>
							</Text>
						</Box>
						<Text
							margin="16px 0px 0px 0px"
							font="--base"
							display="block"
							text-align="left"
							color="--darkL2"
							width="312px"
						>
							Real-World Resources: Engaging materials that mirror actual language use, enhancing real-life communication skills.
						</Text>
					</Box>
					<Box
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						border-radius={0}
						width="100%"
					>
						<Image
							src="/images/background/book_shelf.jpg"
							border-radius="0 10px 0 10px"
							object-fit="cover"
							position="absolute"
							top="0px"
							// left="0px"
							bottom="0px"
							right={20}
							max-height="100%"
							// width="312px"
							width="inherit"
							srcSet="/images/background/book_shelf.jpg"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							className="image-mask"
						/>
						{/* <Box
                            className="image-mask"
							sm-bottom="-2.459072380952408px"
							sm-left={20}
							// sm-right="auto"
							sm-top="0px"
							sm-height="375.05295238095243px"
						/> */}
					</Box>
				</Box>
				<Box position="relative" display="flex" flex-direction="column" className="box-image">
					<Box padding="0px 8px 0px 8px" margin="250px 0px 16px 0px" sm-margin="0px 0px 16px 0px">
						<Box min-width="100px" min-height="8px" padding="6px 0px 6px 0px" display="flex">
							<Text className="text-numbers" margin="0px 0px 0px 0px" letter-spacing="-14px" font="140px/50px  'Georgia', Sans-serif">
								02
							</Text>
							<Text font="normal bold 22px/37px  'Georgia', Sans-serif" color="#333333" letter-spacing={0} transform="translate3d(-64px, 24px, 0px)">
								Tailored Individual Approach
							</Text>
						</Box>
						<Text
							margin="16px 0px 0px 0px"
							font="--base"
							display="block"
							text-align="left"
							color="--darkL2"
							width="312px"
						>
							Customized Learning Path: Adapted to your individual pace and style, our courses offer a personalized and effective language learning experience.
						</Text>
					</Box>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						border-radius={0}
					>
						<Image
							src="/images/background/people.png"
							border-radius="0 10px 0 10px"
							// width="312px"
							width="inherit"
							object-fit="cover"
							position="absolute"
							top="0px"
							// left="0px"
							bottom="0px"
							right={20}
							max-height="100%"
							// srcSet=""
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							className="image-mask"
						/>
					</Box>
				</Box>
				<Box position="relative" display="flex" flex-direction="column" className="box-image">
					<Box padding="0px 8px 0px 8px" margin="100px 0px 16px 0px" sm-margin="0px 0px 16px 0px">
						<Box min-width="100px" min-height="8px" padding="6px 0px 6px 0px" display="flex">
							<Text className="text-numbers" margin="0px 0px 0px 0px" letter-spacing="-14px" font="140px/50px  'Georgia', Sans-serif">
								03
							</Text>
							<Text font="normal bold 22px/37px  'Georgia', Sans-serif" color="#333333" letter-spacing={0} transform="translate3d(-64px, 24px, 0px)">
								<Strong>
									Exam Preparation Excellence
								</Strong>
							</Text>
						</Box>
						<Text
							margin="16px 0px 0px 0px"
							font="--base"
							display="block"
							text-align="left"
							color="--darkL2"
							width="312px"
						>
							Exam Success: Rigorous preparation for language proficiency tests, aimed at achieving top results with confidence.
						</Text>
					</Box>
					<Box
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						padding="100% 0px 0px 0px"
						border-radius={0}
						sm-align-self="auto"
						sm-order="0"
						sm-display="block"
						sm-align-items="center"
						sm-justify-content="center"
						sm-align-content="space-between"
					>
						<Image
							src="/images/background/school.png"
							border-radius="0 10px 0 10px"
							// width="312px"
							width="inherit"
							object-fit="cover"
							position="absolute"
							top={0}
							// left={0}
							bottom={0}
							right={20}
							max-height="100%"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							className="image-mask"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
    )
}

export default SolutionImages