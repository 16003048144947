import React from 'react';

import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Strong, Hr, Icon } from "@quarkly/widgets";
import { Override} from "@quarkly/components";
import Slider from "react-slick";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { FaBeer } from 'react-icons/fa';


function PanelHeader({smallText, bigText, blueBackground, whiteColor}) {
    const color = blueBackground ? "#F0F8FF" : "--dark";
    const primaryColor = whiteColor ? "#F0F8FF" : "default";
    return (
        <>
        <Text
            margin="0px 0px 0px 0px"
            color={color}
            font="--lead"
            md-margin="10px 0px 20px 0px"
          >
            {smallText}
          </Text>
          <Text
            className="section-primary-text"
            color={primaryColor}
            margin="0px 0px 0px 0px"
            font="normal 900 42px/1.2 --fontFamily-googleNotoSerifGeorgian"
            md-margin="0px 0px 30px 0px"
            display="block"
            height="fit-content"
          >
            {bigText}
          </Text>
        </>
    )
}

export default PanelHeader