import React from 'react';

import { Theme, Link, Image, Text, LinkBox, Box, Section, Span, Button, Strong, Hr, Icon } from "@quarkly/widgets";
import { Override} from "@quarkly/components";

import HomeTrainingSingle from './home-trainings-single'
import './home-trainings.css'

function HomeTrainings() {
    return (
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
        display="block"
      >
        <Override slot="SectionContent" max-width="80%" />
        <Box
          margin="20px 0px 32px 0px"
          display="block"
          flex-direction="column"
          align-items="flex-start"
          padding="0px 0px 0px 0%"
          sm-padding="0px 0px 0px 0px"
        >
          {/* <Text font="normal bold 14px/24px &quot;Muli&quot;,  &quot;Mulish&quot;, Sans-serif" color="#333333" max-width="600px" letter-spacing="2.8px">
					OUR TRAININGS
				</Text> */}
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            font="--lead"
            md-margin="10px 0px 20px 0px"
          >
            Our Trainings
          </Text>
          {/* <Text
					font="normal bold 48px/55px 'Georgia', Sans-serif"
					color="#00996d"
					text-align="left"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					We prepared the{" "}
					<br />
					best trainings for you!
				</Text> */}
          <Text
            className="section-primary-text"
            margin="0px 0px 0px 0px"
            // color="#00996d"
            font="normal 900 42px/1.2 --fontFamily-googleNotoSerifGeorgian"
            md-margin="0px 0px 30px 0px"
            display="block"
            height="fit-content"
          >
            We prepared the <br />
            best trainings for you!
          </Text>
        </Box>
        {/* TODO */}

        <HomeTrainingSingle
          trainingName="French"
          description="The perennial plants are available in the big busy city. Pay attention to our greenhouse project performed in the center of the megalopolis."
          image="/images/background/french4.png"
        />

		<HomeTrainingSingle
          trainingName="German"
          description="The perennial plants are available in the big busy city. Pay attention to our greenhouse project performed in the center of the megalopolis."
          image="/images/background/german3.png"
		  height={240}
        />

		<HomeTrainingSingle
          trainingName="English"
          description="The perennial plants are available in the big busy city. Pay attention to our greenhouse project performed in the center of the megalopolis."
          image="/images/background/eng6.png"
        />



        {/* <LinkBox
				display="flex"
				margin="0px 0px 84px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
				flex-direction="row"
				sm-flex-direction="column-reverse"
			>
				<Box
					width="40%"
					padding="100px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
					sm-padding="16px 12px 16px 0px"
				>
					<Box
						padding="0px 50px 0px 0px"
						lg-width="70%"
						sm-width="100%"
						lg-padding="0px 0px 0px 0px"
						color="#333333"
					>
						<Text margin="0px 0px 8px 0px" font="normal bold 30px/34px --fontFamily-googleNotoSerifGeorgian" color="#333333">
							French
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 300 18px/31px &quot;Mulish&quot;, Sans-serif"
							color="#777777"
							letter-spacing="0.3px"
							width="80%"
							sm-width="100%"
						>
							The perennial plants are available in the big busy city. Pay attention to our greenhouse project performed in the center of the megalopolis.
						</Text>
						<Box
							min-width="100px"
							display="flex"
							max-height="fit-content"
							padding="10px 0px 0px 0px"
							height="fit-content"
						>
							<Text
								margin="0px 0px 0px 0px"
								max-height="max-content"
								display="inline-flex"
								padding="0px 20px 0px 0px"
								width="fit-content"
								height="fit-content"
								color="#333333"
								font="bold 18px/40px &quot;Mulish&quot;, Sans-serif"
							>
								From
								<br />
								To
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								max-height="max-content"
								display="inline-flex"
								padding="0px 0px 0px 10px"
								height="fit-content"
								color="#00996d"
								font="bold 18px/40px &quot;Mulish&quot;, Sans-serif"
							>
								A1.1
								<br />
								B2.2
							</Text>
						</Box>
					</Box>
				</Box>
				<Image
					src="/images/background/french.png"
					border-radius="0 10px 0 10px"
					max-width="50%"
					width="100%"
					object-fit="contain"
					lg-max-height="392px"
					sm-max-height="213px"
					sm-max-width="100%"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				/>
			</LinkBox> */}
        
      </Section>
    );
}

export default HomeTrainings