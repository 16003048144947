import React from 'react';

import { Text, Box, Section } from "@quarkly/widgets";
import { Override} from "@quarkly/components";

import './solutions.css'

function Solutions() {
    return (
        <Section padding="80px 0 0px 0" sm-padding="40px 0 40px 0" background="rgba(247, 251, 255, 0.1) url()" flex-direction="row">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--dark" font="--lead" md-margin="10px 0px 20px 0px">
					Best Solutions
				</Text>
				<Text
                    className="section-primary-text"
					margin="0px 0px 0px 0px"
					// color="#00996d"
					font="normal 900 42px/1.2 --fontFamily-googleNotoSerifGeorgian"
					md-margin="0px 0px 30px 0px"
					display="block"
					height="fit-content"
				>
					We propose
					<br />
					the best solutions
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="flex-start"
				md-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="#777777"
					font="normal 300 18px/31px --fontFamily-googleMulish"
					sm-padding="0px 0px 0px 0px"
					padding="40px 0px 0px 0px"
					letter-spacing="0.27px"
				>
					Discover new skills with our easy, user-friendly online courses. We're all about covering the small details and the big concepts, making your learning journey enjoyable and rewarding.
				</Text>
			</Box>
		</Section>
    )
}

export default Solutions